
/*
 *   File : handshake.js
 *   Author URI : www.evoqins.com
 *   Description : Page to show login status of the user from MT mobile app
 *   Integrations : null
 *   Version : v1.0
 *   Created : 03-10-2024
 */
// package import
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// component import
import { GradientButton } from '../../Component/CTA'
import { CustomLoader } from '../../Component/Other';

export default function HandShake() {

    const navigator = useNavigate();

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const scope = params.get('scope');
        const state = params.get('state');

        setTimeout(() => { setLoader(false) }, 3000);
    }, []);

    function _navigateBack() {

    }

    return (
        <div className="container padding-64px-lr-md ">
            <div className="container h-100vh padding-64px-lr-md">
                {/* Header */}
                <div className="row mt-4">
                    <div className="col-12 text-center">
                        <img src={require('../../Assets/Images/master-trust-logo.svg').default}
                            alt="mt wealth - powered by Master Trust" />
                    </div>
                </div>

                {/* Card */}
                <div className='row my-5 py-5'>
                    <div className='col-10 offset-1'>
                        <div className='text-center e-hand-shake-card'>
                            {
                                loader === true ?
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <CustomLoader />
                                    </div>
                                    :
                                    <>
                                        <img src={require('../../Assets/Images/Subtract.svg').default}
                                            alt="mt wealth - hand shake failed" />
                                        <h6 className='e-poppins-medium e-font-24 line-height-32px color-black mt-3'>Request received</h6>
                                        <p className='e-poppins-regular e-font-20 line-height-30px color-gray mt-3'>
                                            You do not have an account in Mtwealth.
                                            We have received your request.
                                            Our support team will contact you for further steps.</p>

                                        <GradientButton label="Go back"
                                            className="padding-8px-tb padding-16px-lr mt-4"
                                            handleClick={_navigateBack} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};